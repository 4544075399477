<template>
  <v-main>
    <v-app-bar>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-card class="card-background-image" width="20%" height="120px" elevation="0" color="transparent"></v-card>
    </v-app-bar>
    <v-navigation-drawer
        v-model="drawer"
        sticky
        style="width: 100%"
    >
      <v-list
          nav
          dense
      >
        <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
        >
          <v-list-item @click="routeToHome">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item @click="routeToPriceList">
            <v-list-item-title>Preise</v-list-item-title>
          </v-list-item>
          <v-list-item @click="routeToProducts">
            <v-list-item-title class="align-self-center">Produkte</v-list-item-title>
          </v-list-item>
          <v-list-item @click="routeToOpeningHours">
            <v-list-item-title>Öffnungszeiten</v-list-item-title>
          </v-list-item>
          <v-list-item @click="routeToProjects">
            <v-list-item-title>Projekte</v-list-item-title>
          </v-list-item>
          <v-list-item @click="routeToEvents">
            <v-list-item-title>Termine</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-main>
</template>

<script>
export default {
  name: "NavigationMenuMobile",
  data: () => ({
    drawer: false,
    group: null,
  }),
  // Do routing for each button
  methods: {
    routeToHome() {
      this.$router.push("/");
    },
    routeToPriceList() {
      this.$router.push("/Preisliste");
    },
    routeToProducts() {
      this.$router.push("/Produkte");
    },
    routeToOpeningHours() {
      this.$router.push("/Öffnungszeiten");
    },
    routeToProjects() {
      this.$router.push("/Projekte");
    },
    routeToEvents() {
      this.$router.push("/Termine");
    }
  }

}



</script>

<style scoped>
.v-main {
  z-index: 2;
}
.card-background-image {
  background-image: url("@/assets/logo_color_transparent.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.v-list-item-title {
  font-size: 30px;
  line-height: 50px;
}
</style>
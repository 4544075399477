// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
    theme: {
        themes: {
            light: {
                primary: '#3f51b5',
                secondary: '#b0bec5',
                accent: '#8c9eff',
                error: '#b71c1c',
                background: '#f0e3d2',
                transparent: '#00000000',
                navbar: 'rgb(255,255,255)',
                black: 'rgb(0,0,0)',
                backgroundGrey: '#252525',
            }
        }
    }
    }
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)

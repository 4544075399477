<template>
  <v-app class="backgroundimg">
    <!-- show navigation-menu when isMobile() returns false-->
    <!-- show navigation-menu-mobile when isMobile() returns true-->
    <navigation-menu class="animate__animated animate__fadeIn" v-if="!isMobile" ></navigation-menu>
    <navigation-menu-mobile class="animate__animated animate__fadeIn" v-if="isMobile"></navigation-menu-mobile>
    <v-main>
      <router-view/>
    </v-main>
    <footer-component></footer-component>
  </v-app>
</template>

<script>

import NavigationMenu from "@/components/NavigationMenu.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import NavigationMenuMobile from "@/components/NavigationMenuMobile.vue";

export default {
  name: 'App',
  components: {NavigationMenuMobile, FooterComponent, NavigationMenu},

  data: () => ({
    isMobile: false,
  }),
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 1000) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
}
</script>

<style>
.v-card-title{
  font-size: 1.7rem;
}

.v-card-text{
  font-size: 1.0rem;
}

.backgroundimg{
  background-color: #ffffff;
  /*background-image: url("@/assets/low-poly-grid-haikei.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

   */
}

@font-face {
  font-family: 'Roboto';
  src: url("@/assets/fonts/Roboto-Thin.ttf") format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url("@/assets/fonts/Roboto-Light.ttf") format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('@/assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('@/assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('@/assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('@/assets/fonts/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}


</style>
<template>
  <v-card
      color="navbar"
      elevation="8"
      class="d-flex flex-row justify-space-around"
  style="background-color: rgba(255,255,255,1);">

    <v-btn class="align-self-center" width="200px" elevation="0" color="transparent" @click="routeToHome">Home</v-btn>
    <v-btn class="align-self-center" width="200px" elevation="0" color="transparent" @click="routeToPriceList">Preisliste</v-btn>
    <v-btn class="align-self-center" width="200px" elevation="0" color="transparent" @click="routeToProducts">Produkte</v-btn>
    <v-card class="card-background-image" width="20%" height="120px" elevation="0" color="transparent"></v-card>
    <v-btn class="align-self-center" width="200px" elevation="0" color="transparent" @click="routeToOpeningHours">Öffnungszeiten</v-btn>
    <v-btn class="align-self-center" width="200px" elevation="0" color="transparent" @click="routeToProjects">Projekte</v-btn>
    <v-btn class="align-self-center" width="200px" elevation="0" color="transparent" @click="routeToEvents">Termine</v-btn>

  </v-card>
</template>

<script>
export default {
  name: "NavigationMenu",
  // Do routing for each button
  methods: {
    routeToHome() {
      this.$router.push("/");
    },
    routeToPriceList() {
      this.$router.push("/Preisliste");
    },
    routeToProducts() {
      this.$router.push("/Produkte");
    },
    routeToOpeningHours() {
      this.$router.push("/Öffnungszeiten");
    },
    routeToProjects() {
      this.$router.push("/Projekte");
    },
    routeToEvents() {
      this.$router.push("/Termine");
    }
  }

}



</script>

<style scoped>

.card-background-image {
  background-image: url("@/assets/logo_color_transparent.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
<template>
  <v-card class="ma-12 d-flex flex-column justify-center rounded-xl" color="black" elevation="20">
    <v-card-title class="ma-12 align-self-center text-h3 font-weight-bold">Unser Team</v-card-title>
    <div class="d-inline flex-column justify-end">
      <div class="ms-16 me-16 mb-6 d-flex justify-center">
        <div class="d-flex flex-row">
          <v-img src="@/assets/Bilder/Team/Martin2000x2000.jpg" class=" me-6">
            <div class="overlay d-flex flex-column justify-center">
              <div class="align-center">
              <v-card-title class="text-center mb-6">Martin Eisert</v-card-title>
              <v-card-title class="text-center text-h6">
                Diplomcolorist und Friseurmeister <br>
                Gründer der Konkurrenz</v-card-title>
              </div>
            </div>
          </v-img>
          <v-img src="@/assets/Bilder/Team/Katrin2000x2000.jpg" >
            <div class="overlay d-flex flex-column justify-center">
              <div class="align-center">
                <v-card-title class="text-center mb-6">Katrin Klammt</v-card-title>
                <v-card-title class="text-center text-h6">
                  Friseurin und Diplomcoloristin</v-card-title>
              </div>
            </div>
          </v-img>
          <v-img src="@/assets/Bilder/Team/Manu2000x2000.jpg" class="ms-6 ">
            <div class="overlay d-flex flex-column justify-center">
              <div class="align-center">
                <v-card-title class="text-center mb-6">Manuela Michel</v-card-title>
                <v-card-title class="text-center text-h6">
                  Friseurin</v-card-title>
              </div>
            </div>
          </v-img>
        </div>
      </div>
      <div class="ms-16 me-16 mb-6 d-flex justify-center">
        <div class="d-flex flex-row">
        <v-img src="@/assets/Bilder/Team/Anastasia2000x2000.jpg" class=" me-6">
          <div class="overlay d-flex flex-column justify-center">
            <div class="align-center">
              <v-card-title class="text-center mb-6">Anastasia Oleinikowa</v-card-title>
              <v-card-title class="text-center text-h6">
                Friseurmeisterin</v-card-title>
            </div>
          </div>
        </v-img>
        <v-img src="@/assets/Bilder/Team/Tine2000x2000.jpg" class="ms-6 ">
          <div class="overlay d-flex flex-column justify-center">
            <div class="align-center">
              <v-card-title class="text-center mb-6">Tine Block</v-card-title>
              <v-card-title class="text-center text-h6">
                Rezeptionistin</v-card-title>
            </div>
          </div>
        </v-img>
        </div>
      </div>
      <div class="ms-16 me-16 mb-6 d-flex justify-center">
        <div class="d-flex flex-row">
          <v-img src="@/assets/Bilder/Team/Pepe2000x2000.jpg" class="ms-6 ">
            <div class="overlay d-flex flex-column justify-center">
              <div class="align-center">
                <v-card-title class="text-center mb-6">Pepe</v-card-title>
                <v-card-title class="text-center text-h6">
                Happieness Officer</v-card-title>
              </div>
            </div>
          </v-img>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "TeamComponent"
}
</script>

<style scoped>

.v-img {
  width: 360px;
  height: 360px;
  position: relative;
}

.v-img:hover .overlay {
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(0,0,0,0.5);
}
</style>

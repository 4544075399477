import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/Preisliste',
        name: 'preisliste',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.

        component: () => {
            if (window.innerWidth < 1000) {
                return import(/* webpackChunkName: "about" */ '../views/PricelistViewMobile.vue')
            } else {
                return import(/* webpackChunkName: "about" */ '../views/PricelistView.vue')
            }
        }
    },
    {
        path: '/Produkte',
        name: 'produkte',
        component: () => {
            if (window.innerWidth < 1000) {
                return import(/* webpackChunkName: "about" */ '../views/ProductsViewMobile.vue')
            } else {
                return import(/* webpackChunkName: "about" */ '../views/ProductsView.vue')
            }
        }
    },
    {
        path: '/Öffnungszeiten',
        name: 'öffnungszeiten',
        component: () => {
                return import(/* webpackChunkName: "about" */ '../views/OpeningHoursView.vue')
        }
    },
    {
        path: '/%C3%96ffnungszeiten',
        name: 'Oefnnungszeiten',
        component: () => {
                return import(/* webpackChunkName: "about" */ '../views/OpeningHoursView.vue')
        }
    },
    {
      path: '/Projekte',
        name: 'projekte',
        component: () => {
            if (window.innerWidth < 1000) {
                return import(/* webpackChunkName: "about" */ '../views/ProjectsViewMobile.vue')
            } else {
                return import(/* webpackChunkName: "about" */ '../views/ProjectsView.vue')
            }
        }
    },
    {
      path: '/Termine',
        name: 'termine',
        component: () => {
            if (window.innerWidth < 1000) {
                return import(/* webpackChunkName: "about" */ '../views/EventsViewMobile.vue')
            } else {
                return import(/* webpackChunkName: "about" */ '../views/EventsView.vue')
            }
        }
    },
    {
        path: '/Impressum',
        name: 'impressum',
        component: () => {
            return import(/* webpackChunkName: "about" */ '../views/ImprintView.vue')
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

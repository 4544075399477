<template>
  <div>
    <header-component></header-component>
    <div class="d-flex justify-start"  v-if="width >= 1800">
      <div class="mt-12 d-flex">
        <div>
          <img :src='sissors' style="height: 950px">
          <img :src='kera' style="height: 650px" class="img-overlap">
        </div>
        <div class="d-flex" style="height: 550px; width: 35%">
          <div class="d-flex">
            <div class="align-self-center d-flex flex-column justify-space-around">
              <v-card-title>Unsere Produkte</v-card-title>
              <v-card-text class="align-self-center">Generell wird während der gesamten Bedienung mit Loreal und
                Petritsch
                Professional
                gearbeitet. Hierbei handelt
                es sich um eine Ätherische Öl Haarpflege die auf der Basis von Bergkristallwasser erstellt wird und für
                Bremen einzigartig in diesem Salon erhältlich ist. Außerdem wird mit der Marke Kérastase gearbeitet,
                wessen Produkte auch im Laden erhältlich sind.
              </v-card-text>
              <v-btn width="50%" color="black" @click="toProducts">Zu unseren Produkten</v-btn>
            </div>
            <v-img :src='pro' style="height: 250px"></v-img>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-center mb-2"  v-if="width < 1800">
      <div class="mt-12 d-flex">
          <div class="d-flex">
            <div class="align-self-center d-flex flex-column justify-space-around">
              <v-card-title>Unsere Produkte</v-card-title>
              <v-card-text class="align-self-center">Generell wird während der gesamten Bedienung mit Loreal und
                Petritsch
                Professional
                gearbeitet. Hierbei handelt
                es sich um eine Ätherische Öl Haarpflege die auf der Basis von Bergkristallwasser erstellt wird und für
                Bremen einzigartig in diesem Salon erhältlich ist. Außerdem wird mit der Marke Kérastase gearbeitet,
                wessen Produkte auch im Laden erhältlich sind.
              </v-card-text>
              <v-btn width="50%" color="black" class="ms-4 mb-6" @click="toProducts">Zu unseren Produkten</v-btn>
            </div>
          </div>
        </div>
    </div>
    <v-card width="100%" class="rounded-0 d-flex justify-center mb-12 pb-12" height="700px" elevation="8" color="black" v-if="width >=1600">
      <v-row>
        <v-col cols="4" class="d-flex flex-column mt-12">
          <div class="d-flex flex-column align-end mt-12">
            <p class="text-h1 text--primary font-weight-bold" style="text-align: end">Die<br>Konkurrenz</p>
            <div class="d-flex justify-end" style="width: 70%">
              <v-divider thickness="5px" length="40%" class="ml-12 mt-5 align-content-end"></v-divider>
            </div>
          </div>
        </v-col>
        <v-col class="d-flex flex-column mt-12">
          <div class="d-flex flex-column mt-12">
            <v-divider class="mt-5" thickness="5px" style="align-self: center; width: 80%"></v-divider>
            <p class="text-h2 text--primary font-weight-bold mt-2" style="text-align: center">Unser Fokus</p>
            <v-divider class="mt-5" thickness="5px" style="align-self:center;width: 80%"></v-divider>
            <div class="d-flex flex-row justify-space-between mt-12" style="width: 80%; align-self: center">
              <v-expansion-panels v-model="expanded">
                <v-expansion-panel
                    title="Beratung"
                    text="Stress Hektik gibt es hier nicht und der Friseur arbeitet hier ganz unter dem Motto des
          Schönheitsberaters
          ein guter Freund der mit Rat und Tat zur Seite steht."
                    color="black"
                    class="rounded-0"></v-expansion-panel>
                <v-expansion-panel title="Qualität"
                                   text="Natürlich steht die Friseurqualität besonders im Mittelpunkt die Zeit spielt dabei eine große Rolle deshalb
          wird hier auch nicht alle 30 Minuten -Der Nächste bitte- gerufen sondern allein nur für die Beratung sind
                hier
                bis zu 30 min eingeplant somit kann es gut passieren dass ein Mitarbeiter bis zu drei Stunden für Sie ganz
                alleine da ist. "
                                   color="black" class="rounded-0 test"></v-expansion-panel>
                <v-expansion-panel title="Wellness" text="Auf 300m² Fläche erstreckt sich ein Qualitätsoase zum entspannen. Zwischen den Dienstleistungen waschen,
          schneiden, fönen, Colorationstechniken und allem anderen wird hier ganz besonders großen Wert auf Entspannung
          und Wohlfühlen gelegt. Eine externe Wasch Lounge die durch eine Wasserwand gerahmt wird und Clubsessel
          runden
          das ganze ab. Ob Farbwechselnder Sternenhimmel oder das große Kaffe-, Tee- und Kaltgetränksortiment hier ist
          alles ein Genuß." color="black" class="rounded-0"></v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-card width="100%" class="rounded-0 d-flex justify-center mb-12 pb-12" height="800px" elevation="8" color="black" v-if="width < 1600">
          <div class="d-flex flex-column mt-12">
            <v-divider class="mt-5" thickness="5px" style="align-self: center; width: 80%"></v-divider>
            <p class="text-h2 text--primary font-weight-bold mt-2" style="text-align: center">Unser Fokus</p>
            <v-divider class="mt-5" thickness="5px" style="align-self:center;width: 80%"></v-divider>
            <div class="d-flex flex-row justify-space-between mt-12" style="width: 80%; align-self: center">
              <v-expansion-panels v-model="expanded">
                <v-expansion-panel
                    title="Beratung"
                    text="Stress Hektik gibt es hier nicht und der Friseur arbeitet hier ganz unter dem Motto des
          Schönheitsberaters
          ein guter Freund der mit Rat und Tat zur Seite steht."
                    color="black"
                    class="rounded-0"></v-expansion-panel>
                <v-expansion-panel title="Qualität"
                                   text="Natürlich steht die Friseurqualität besonders im Mittelpunkt die Zeit spielt dabei eine große Rolle deshalb
          wird hier auch nicht alle 30 Minuten -Der Nächste bitte- gerufen sondern allein nur für die Beratung sind
                hier
                bis zu 30 min eingeplant somit kann es gut passieren dass ein Mitarbeiter bis zu drei Stunden für Sie ganz
                alleine da ist. "
                                   color="black" class="rounded-0 test"></v-expansion-panel>
                <v-expansion-panel title="Wellness" text="Auf 300m² Fläche erstreckt sich ein Qualitätsoase zum entspannen. Zwischen den Dienstleistungen waschen,
          schneiden, fönen, Colorationstechniken und allem anderen wird hier ganz besonders großen Wert auf Entspannung
          und Wohlfühlen gelegt. Eine externe Wasch Lounge die durch eine Wasserwand gerahmt wird und Clubsessel
          runden
          das ganze ab. Ob Farbwechselnder Sternenhimmel oder das große Kaffe-, Tee- und Kaltgetränksortiment hier ist
          alles ein Genuß." color="black" class="rounded-0"></v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
    </v-card>
    <team-component class="mt-16" v-if="width >=1300"></team-component>
    <team-component-mobile class="mt-16" v-if="width < 1300"></team-component-mobile>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import HeaderComponent from "@/components/HeaderComponent.vue";
import TeamComponent from "@/components/TeamComponent.vue";
import TeamComponentMobile from "@/components/TeamComponentMobile.vue";
// Components

export default defineComponent({
  name: 'HomeView',
  methods: {
    toProducts() {
      this.$router.push('/products');
    }
  },
  data() {
    return {
      sissors: require('@/assets/Bilder/Details/sissors.png'),
      kera: require('@/assets/Bilder/Details/kerastase.png'),
      logo: require('@/assets/Bilder/Details/logo.png'),
      pro: require('@/assets/Bilder/Details/pro.png'),
      hover1: false,
      hover2: false,
      hover3: false,
      expanded: 2
    }
  },
  components: {
    TeamComponentMobile,
    TeamComponent,
    HeaderComponent
  },
  computed: {
    width() {
      return window.innerWidth;
    }
  },
});
</script>

<style>

.centerText {
  text-align: center;
}

.cardColor {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .9;
  position: absolute;
  width: 100%;
}

.img-overlap {
  position: absolute;
  top: 1100px;
  left: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0);
}

.v-divider {
  opacity: 1;
}

.v-expansion-panel-title {
  font-size: 25px;
}

.v-expansion-panel-title__overlay {
  background-color: #2a2a2a !important;
  color: white;
}

.v-expansion-panel-text {
  background-color: #000000 !important;
  color: white;
}
</style>

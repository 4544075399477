<template>
  <div>
    <v-main v-if="width > 1000" class="big">
      <div class="header-video mb-n2">
        <!-- scale video height down with width-->
        <video autoplay loop muted playsinline class="video" :src="videoSrc" type="video/mp4"
               style="width: 100%; height: 100%; object-fit: cover; object-position: center; z-index: -1"></video>
        <!-- scale div down with width-->
        <div class="video-overlay d-flex flex-column justify-center" v-if="width >= 1800">
          <!-- display h2 -> h3 -> h4 card-title scaling with screen width-->
          <div>
            <v-card-title class="text-center text-start font-weight-medium text-h1" style="font-size: 20px">Bremens
              Bester <br>Colorist
            </v-card-title>
            <v-divider class="ms-5" thickness="3px" length="70px" style="color: rgb(0,0,0)"></v-divider>
            <v-btn class="mt-6 ms-5 rounded-0" color="black" @click="goToTermin">Termin Buchen</v-btn>
          </div>
        </div>
        <div class="video-overlay-medium d-flex flex-column justify-center" v-if="width < 1800 && width > 1000">
          <div>
            <v-card-title class="text-center text-start font-weight-medium text-h2" style="font-size: 20px">Bremens
              Bester Colorist
            </v-card-title>
            <v-divider class="ms-5" thickness="3px" length="70px" style="color: rgb(0,0,0)"></v-divider>
            <v-btn class="mt-6 ms-5 rounded-0" color="black"  @click="goToTermin">Termin Buchen</v-btn>
          </div>
        </div>

      </div>
      <v-divider thickness="100px" class="mt-n2"></v-divider>
    </v-main>
    <v-main v-if="width <= 1000" class="small">
      <div class="header-video mb-n2">
        <video autoplay loop muted playsinline class="video" :src="videoSrc" type="video/mp4"
               style="width: 100%; height: 100%; object-fit: cover; object-position: center; z-index: -1"></video>
        <div class="video-overlay-small d-flex flex-column justify-center" v-if="width <= 1000">
          <div>
            <v-card-title class="text-center text-start font-weight-medium text-h4" style="font-size: 20px">Bremens
              Bester
              Colorist
            </v-card-title>
            <v-divider class="ms-5" thickness="3px" length="70px" style="color: rgb(0,0,0)"></v-divider>
            <v-btn class="mt-6 ms-5 rounded-0 text-md-h6" color="black"  @click="goToTermin">Termin Buchen</v-btn>
          </div>
        </div>
      </div>
    </v-main>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
  data() {
    return {
      videoSrc: require('@/assets/Bilder/Videos/header.mp4')
      //imgSrc: require('@/assets/Bilder/PXL_20230207_105904149.jpg')
    }
  },
  computed: {
    width() {
      return window.innerWidth;
    }
  },
  methods: {
    goToTermin() {
      this.$router.push('/Termine')
    }
  }


}
</script>

<style scoped>

.video-overlay {
  position: absolute;
  top: 60px;
  left: 550px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0);
}

.video-overlay-medium {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0);
}


.video-overlay-small {
  position: absolute;
  top: 20px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0);
}


/*Scale video resolution with screen width so the height reduces*/
.big {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.small {
  margin-top: -130px;
  position: relative;
  width: 100%;
  overflow: hidden;
}


</style>
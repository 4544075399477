<template>
  <v-footer color="black" class="d-flex justify-space-around flex-column">
    <div>
      <v-card-text>Konkurrenz 2023 ©</v-card-text>
      <v-divider></v-divider>
      <socials class="d-flex justify-space-around mt-4">
        <v-icon>mdi-instagram</v-icon>
        <v-icon>mdi-facebook</v-icon>
        <v-icon>mdi-email</v-icon>
      </socials>

    </div>
    <v-card-text @click="goToImprint()">Impressum</v-card-text>
  </v-footer>
</template>

<script>
export default {
  name: "FooterComponent",
  methods: {
    goToImprint() {
      this.$router.push("/Impressum");
    }
  }
}
</script>

<style scoped>

</style>